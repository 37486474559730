import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  detailRow: {
  },
  textLeft: {
    display: 'flex',
    textAlign: 'left',
    flex: 1,
  },
  textRight: {
    display: 'flex',
    textAlign: 'right',
    fontSize: layout.window.toNormH(16),
    flex: 1,
  },
  detailTitle: { 
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
  },
  detailText: { 
    fontSize: layout.window.toNormH(16),
    textAlign: 'right',
    flex: 1,
  },
}