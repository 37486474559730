import React from 'react'
import Base from '../../components/Base'
import Text from '../../components/Text'
import { styles } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faMobile, faMobileAlt, faPowerOff, faSatelliteDish, faUser, faUserCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import layout from '../../constants/layout'
import { theme } from '../../constants/theme'
import { useNavigate } from 'react-router-dom'
import { saveTokens } from '../../service/AuthService'
import { useSelector } from 'react-redux'
import { userProfileSelector } from '../../redux/selectors/app'
import { showErrorToast, showInfoToast } from '../../utilities/toast'

export default function Dashboard() {
  const navigate = useNavigate();
  const userProfile = useSelector(userProfileSelector);
  const logout = () => {
    saveTokens('','');
    navigate('/');
  }
  const disabledItemClick = (item) => {
    showInfoToast(`${item} is coming soon.`);
  }
  return (
    <Base>
      <div className='d-flex flex-row justify-content-between'>
        <Text style={styles.headerName}>Hi {userProfile?.fullName?.split(' ')[0]}</Text>
        {/* <FontAwesomeIcon icon={faUserCircle} fontSize={layout.window.toNormH(25)} color={theme.COLOR.ThemePrimary}/> */}
        <FontAwesomeIcon icon={faPowerOff} color={theme.COLOR.Red} fontSize={layout.window.toNormH(24)} onClick={logout}/>
      </div>
      <div style={styles.horizontalLine}></div>
      {/* <div style={styles.divisionContainer}>
        <Text style={styles.divisionTitle}>Add, Pay, Track</Text>
        <Text style={styles.divisionSubTitle}>Slice all your bills on Monyy</Text>
        <div className='row mt-3' style={styles.bannerContainer}>
          <span style={styles.banner}></span>
          <span style={styles.banner}></span>
          <span style={styles.banner}></span>
          <span style={styles.banner}></span>
          <span style={styles.banner}></span>
        </div>
      </div> */}
      <div style={styles.divisionContainer}>
        <Text style={styles.divisionTitle}>Recharges & Bill payments</Text>
        <div className='d-flex flex-row flex-wrap mt-4 justify-content-between' style={{}}>
          <div className='d-flex flex-column align-items-center' onClick={() => navigate('/mobileRecharge')}>
            <span className='d-flex' style={styles.iconContainer}>
              <FontAwesomeIcon icon={faMobileAlt} color={theme.COLOR.ThemePrimary} fontSize={layout.window.toNormH(30)}/>
            </span>
            <Text style={styles.optionTitle}>Mobile<br/>Recharge</Text>
          </div>

          <div className='d-flex flex-column align-items-center' onClick={() => navigate('/electricity')}>
            <span className='d-flex' style={styles.iconContainer}>
              <FontAwesomeIcon icon={faBolt} color={theme.COLOR.ThemePrimary} fontSize={layout.window.toNormH(30)}/>
            </span>
            <Text style={styles.optionTitle}>Electricity</Text>
          </div>

          <div className='d-flex flex-column align-items-center' onClick={() => disabledItemClick('DTH recharge')}>
            <span className='d-flex' style={styles.iconContainer}>
              <FontAwesomeIcon icon={faSatelliteDish} color={theme.COLOR.ThemePrimaryDisabled} fontSize={layout.window.toNormH(30)}/>
            </span>
            <Text style={styles.optionTitleDisabled}>DTH</Text>
          </div>

          <div className='d-flex flex-column align-items-center' onClick={() => disabledItemClick('Brondband recharge')}>
            <span className='d-flex' style={styles.iconContainer}>
              <FontAwesomeIcon icon={faWifi} color={theme.COLOR.ThemePrimaryDisabled} fontSize={layout.window.toNormH(30)}/>
            </span>
            <Text style={styles.optionTitleDisabled}>Broadband</Text>
          </div>
        </div>
      </div>
    </Base>
  )
}
