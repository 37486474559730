import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import AppWrapper from './components/AppWrapper';
import Login from './screens/login';
import OtpVerification from './screens/otpVerification';
import PersonalDetails from './screens/personalDetails';
import Dashboard from './screens/dashboard';
import ProtectedRoute from './screens/protectedRoute';
import MobileRecharge from './screens/mobileRecharge';
import LoadingAuth from './screens/loadingAuth';
import PaymentCheckout from './screens/paymentCheckout';
import Electricity from './screens/electricity';
import ElectricityBillDetails from './screens/electricityBillDetails';

function App() {
  return (
    <AppWrapper>
      <Routes>
        <Route
          path="/personalDetails"
          element={
            <ProtectedRoute>
              <PersonalDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/mobileRecharge"
          element={
            <ProtectedRoute>
              <MobileRecharge />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <PaymentCheckout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/electricity"
          element={
            <ProtectedRoute>
              <Electricity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/electricityBillDetails"
          element={
            <ProtectedRoute>
              <ElectricityBillDetails />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<LoadingAuth />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<OtpVerification />} />
        {/* <Route path="/personalDetails" element={<PersonalDetails />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
    </AppWrapper>
  )
  // return (
  
  // );
}

export default App;
