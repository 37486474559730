import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  container: {
    padding: layout.window.toNormW(22),
    paddingTop: layout.window.toNormW(122),
  },
  title: {
    fontSize: layout.window.toNormH(24),
  },
  cardInputContainer: {
    width: '100%',
    borderWidth: layout.window.toNormW(1),
    borderStyle: 'solid',
    borderColor: theme.COLOR.LightGrey,
    borderRadius: layout.window.toNormW(6),
    paddingTop: layout.window.toNormH(14),
    paddingBottom: layout.window.toNormH(14),
    paddingLeft: layout.window.toNormW(10),
    justifyContent: 'center',
  },
  cardInputActiveContainer: {
    width: '100%',
    borderWidth: layout.window.toNormW(1),
    borderStyle: 'solid',
    borderColor: theme.COLOR.ThemePrimary,
    borderRadius: layout.window.toNormW(6),
    paddingTop: layout.window.toNormH(14),
    paddingBottom: layout.window.toNormH(14),
    paddingLeft: layout.window.toNormW(10),
    justifyContent: 'center',
  },
  cardInputErrorContainer: {
    width: '100%',
    borderWidth: layout.window.toNormW(1),
    borderStyle: 'solid',
    borderColor: theme.COLOR.Red,
    borderRadius: layout.window.toNormW(6),
    paddingTop: layout.window.toNormH(14),
    paddingBottom: layout.window.toNormH(14),
    paddingLeft: layout.window.toNormW(10),
    justifyContent: 'center',
  },
  errorText: {
    fontSize: layout.window.toNormH(16),
    color: theme.COLOR.Red,
    marginTop: layout.window.toNormH(4),
  },
  secureText: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular
  },
  stripeLogo: {
    width: layout.window.toNormW(60),
    height: layout.window.toNormH(28),
  },
  statusContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: 'red',
  }
}