import React, { useState } from 'react'
import Base from '../../components/Base'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import layout from '../../constants/layout';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { screenPropsSelector } from '../../redux/selectors/screenProps';
import { SCREENS } from '../../constants/screens';
import { propsSelector } from '../../redux/selectors/screenProps';
import { BbpsOption, BbpsPollingStatus, PollingStatus } from '../../constants';
import { handleBbpsPollingLogic, handlePaymentPollingLogic } from '../../service/BbpsService';
import Text from '../../components/Text';
import StatusPage from './statusPage';
import { showErrorToast } from '../../utilities/toast';
import ElectricityStatusPage from './electricityStatusPage';

export default function PaymentCheckout() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const props = useSelector(propsSelector)[SCREENS.Checkout];
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [error, setError] = useState({
    isError: false,
  });
  const [transactionStatus, setTransactionStatus] = useState(PollingStatus.PROCESSING);
  const [rechargeStatus, setRechargeStatus] = useState(BbpsPollingStatus.PROCESSING);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const rechargeStatusCallback = async (data) => {
    if(data?.status === BbpsPollingStatus.SUCCESS) {
      setRechargeStatus(BbpsPollingStatus.SUCCESS);
    } else if(data?.status === BbpsPollingStatus.PROCESSING) {
      setRechargeStatus(BbpsPollingStatus.PROCESSING);
    } else {
      setRechargeStatus(BbpsPollingStatus.FAILURE);
    }
  }

  const paymentStatusCallback = async (data) => {
    if(data?.status === PollingStatus.SUCCESS) {
      setTransactionStatus(PollingStatus.SUCCESS);
      handleBbpsPollingLogic(props?.referenceId, rechargeStatusCallback);
    } else if(data?.status === PollingStatus.PROCESSING) {
      setTransactionStatus(PollingStatus.PROCESSING);
    } else {
      setTransactionStatus(PollingStatus.FAILURE);
    }
    setLoadingPayment(false);
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      console.log(props);
      const response = await stripe.confirmCardPayment(props?.paymentIntentClientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
        },
      });
      console.log(response, 'xyzxyz')
      setIsLoading(false);
      if(response?.error) {
        setError({
          isError: true,
          message: response?.error?.message,
        });
        showErrorToast(response?.error?.message);
      } else {
        setLoadingPayment(true);
        setShowStatusModal(true);
        handlePaymentPollingLogic(props?.paymentIntentId, paymentStatusCallback);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, 'xyzxyz');
    }
  }

  const getStatusPage = () => {
    switch (props?.bbpsOption) {
      case BbpsOption.ELECTRICITY:
        return <ElectricityStatusPage
          isLoading={loadingPayment}
          paymentStatus={transactionStatus}
          rechargeStatus={rechargeStatus}
        />;
      case BbpsOption.MOBILE_RECHARGE:
      default:
        return <StatusPage
          isLoading={loadingPayment}
          paymentStatus={transactionStatus}
          rechargeStatus={rechargeStatus}
          rechargeAmount={props?.amount}
        />;
    }
  }

  return (
    <>
      <Base
        headerAttributes={{
          title: 'Checkout',
          onBack: () => navigate(-1),
        }}
        footerAttributes={{
          primaryAttributes: {
            title: 'Proceed',
            onClick: handleSubmit,
            isLoading: isLoading,
          }
        }}
      >
        <div className='d-flex flex-column justify-content-center mt-3' style={error?.isError ? styles.cardInputErrorContainer : isFocused ? styles.cardInputActiveContainer : styles.cardInputContainer}>
          <CardElement 
            options={{
              iconStyle: 'solid',
              style: {
                base: {
                  fontSize: layout.window.toNormH(24),
                }
              },
            }}
            onChange={(cardDetails) => {
              console.log(cardDetails?.error)
              if(cardDetails?.error) {
                setError({
                  isError: true,
                  message: cardDetails?.error?.message
                })
              } else {
                setError({
                  isError: false
                })
              }
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
        </div>
      </Base>
      {showStatusModal ? getStatusPage() : null}
    </>
  )
}
