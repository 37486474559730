import { Navigate } from "react-router-dom";
import { SCREENS } from "../constants/screens";
import { setScreenProps } from "../redux/actions/screenProps";
import store from "../redux/store"

const showOtpVerificationScreen = (props, navigate) => {
  const dispatch = store.dispatch;
  dispatch(setScreenProps(SCREENS.OtpVerification, props));
  navigate();
}

const showPersonalDetailsScreen = (props, navigate) => {
  const dispatch = store.dispatch;
  dispatch(setScreenProps(SCREENS.PersonalDetails, props));
  navigate();
}

const showDashboard = (props, navigate) => {
  const dispatch = store.dispatch;
  dispatch(setScreenProps(SCREENS.Dashboard, props));
  navigate();
}

const showCheckout = (props, navigate) => {
  const dispatch = store.dispatch;
  dispatch(setScreenProps(SCREENS.Checkout, props));
  navigate();
}

const showElectricityBillDetails = (props, navigate) => {
  const dispatch = store.dispatch;
  dispatch(setScreenProps(SCREENS.ElectricityBillDetails, props));
  navigate();
}

export default {
  showOtpVerificationScreen,
  showPersonalDetailsScreen,
  showDashboard,
  showCheckout,
  showElectricityBillDetails,
}