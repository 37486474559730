import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  container: {
    width: '100%',
    padding: layout.window.toNormW(22),
    paddingTop: layout.window.toNormH(0),
    flex: 1,
    overflowY: 'scroll',
    paddingBottom: layout?.isMobileDevice ? layout.window.toNormH(100) : layout.window.toNormW(22),
  },
  footerContainer: {
    width: '400px',
    maxWidth: '100%',
    position: layout?.isMobileDevice ? 'fixed' : 'relative',
    padding: layout.window.toNormW(22),
    bottom: 0,
    backgroundColor: 'white'
  },
  button: {
    width: "100%",
    backgroundColor: theme.COLOR.ThemePrimary,
    paddingTop: layout.window.toNormH(12),
    paddingBottom: layout.window.toNormH(12),
  },
  disabledButton: {
    width: "100%",
    backgroundColor: theme.COLOR.ThemePrimary,
    paddingTop: layout.window.toNormH(12),
    paddingBottom: layout.window.toNormH(12),
  },
  headerContainer: {
    padding: layout.window.toNormW(22),
  },
  headerTitle: {
    fontSize: layout.window.toNormH(24),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.PrimaryBlack,
  },
  backIconContainer: {
    width: layout.window.toNormW(40),
    height: layout.window.toNormW(40),
    // backgroundColor: 'black',
  },
  backIcon: {
    fontSize: layout.window.toNormH(24),
  }
}