export const countriesData = [
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    minPhoneLength: 10,
    maxPhoneLength: 11,
  },{
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'China',
    dial_code: '+86',
    code: 'CN',
    minPhoneLength: 10,
    maxPhoneLength: 11,
  },{
    name: 'France',
    dial_code: '+33',
    code: 'FR',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
    minPhoneLength: 10,
    maxPhoneLength: 11,
  },{
    name: 'India',
    dial_code: '+91',
    code: 'IN',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    minPhoneLength: 10,
    maxPhoneLength: 11,
  },{
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
    minPhoneLength: 10,
    maxPhoneLength: 11,
  },{
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'KR',
    minPhoneLength: 9,
    maxPhoneLength: 11,
  },{
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'United States',
    dial_code: '+1',
    code: 'US',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
    minPhoneLength: 10,
    maxPhoneLength: 11,
  },{
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    minPhoneLength: 8,
    maxPhoneLength: 8,
  },{
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    minPhoneLength: 8,
    maxPhoneLength: 10,
  },{
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
    minPhoneLength: 8,
    maxPhoneLength: 9,
  },{
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    minPhoneLength: 8,
    maxPhoneLength: 8,
  },{
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    minPhoneLength: 7,
    maxPhoneLength: 12,
  },{
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
    minPhoneLength: 10,
    maxPhoneLength: 10,
  },{
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    minPhoneLength: 8,
    maxPhoneLength: 9,
  },{
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    minPhoneLength: 8,
    maxPhoneLength: 10,
  },{
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    minPhoneLength: 8,
    maxPhoneLength: 9,
  },{
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    minPhoneLength: 8,
    maxPhoneLength: 9,
  },{
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
    minPhoneLength: 8,
    maxPhoneLength: 9,
  },{
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
    minPhoneLength: 9,
    maxPhoneLength: 9,
  },{
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    minPhoneLength: 9,
    maxPhoneLength: 10,
  },{
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    minPhoneLength: 8,
    maxPhoneLength: 10,
  },
]