import { ACTION_TYPES } from "../constants/actionTypes";

export const setScreenProps = (screenName, props) => {
  return ({ 
    type: ACTION_TYPES.SCREEN_PROPS.SET_SCREEN_PROPS, 
    data: { 
      ...props,
      screenName,
    }, 
  });
}