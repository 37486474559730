import React, { useState } from 'react'
import Base from '../../components/Base'
import Text from '../../components/Text'
import { styles } from './styles'
import Input from '../../components/Input'
import { Button, Modal } from 'react-bootstrap'
import BottomSheet from '../../components/BottomSheet'
import { countriesData } from '../../utilities/countryCodes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import layout from '../../constants/layout'
import { useNavigate } from 'react-router-dom'
import { phoneSignin } from '../../service/AuthService'
import Navigation from '../../navigation'
import { APICallStatus, KeyboardTypes } from '../../constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../utilities/toast'

export default function Login() {
  const [selectedCountry, setSelectedCountry] = useState(countriesData[4]);
  const [countryOptionsVisible, setCountryOptionsVisible] = useState(false);
  const [optionsList, setOptionsList] = useState(countriesData);
  const [searchInput, setSearchInput] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (value) => {
    setSearchInput(value);
    if(!value || value?.length === 0) {
      setOptionsList(countriesData);
    }
    let filteredList = [];
    countriesData.forEach(country => {
      if(country?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1 || country?.dial_code?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1) {
        filteredList.push(country);
      }
    })
    setOptionsList(filteredList);
  }
  
  const onClickLogin = async () => {
    try {
      setIsLoading(true);
      const phoneNumberWithCode = selectedCountry?.dial_code + phoneNumber;
      const response = await phoneSignin({
        phoneNumber: phoneNumberWithCode.split('+')[1],
        countryCode: selectedCountry?.code,
        dialCode: selectedCountry?.dial_code,
      });
      setIsLoading(false);
      if(response?.status === APICallStatus.SUCCESS) {
        Navigation.showOtpVerificationScreen({
          phoneNumber: phoneNumberWithCode,
        }, () => navigate('/otp'));
      } else {
        showErrorToast(response?.message);
      }
    } catch (error) {
      
    }
  }

  return (
    <>
    <Base
      footerAttributes={{
        primaryAttributes: {
          title: 'Get OTP',
          onClick: onClickLogin,
          isLoading: isLoading,
          isDisabled: !(selectedCountry && phoneNumber?.length >= selectedCountry?.minPhoneLength && phoneNumber?.length <= selectedCountry?.maxPhoneLength),
        }
      }}
    >
      <Text style={styles.title}>Get started with Monyy</Text>
      <Text style={styles.inputTitle}>Select your country and enter phone number</Text>
      <div className='d-flex flex-row'>
        <div className='d-flex' style={styles.selectionBox} onClick={() => setCountryOptionsVisible(true)}>
          <img src={`https://flagcdn.com/48x36/${selectedCountry?.code?.toLowerCase()}.png`} style={styles.countryFlag}></img>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <Input
          placeholder={'Enter phone number'}
          type={KeyboardTypes.TELEPHONE}
          inputContainerStyles={styles.inputContainer}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </div>
    </Base>
    <BottomSheet visible={countryOptionsVisible} onClose={() => setCountryOptionsVisible(false)}>
      <div style={{height: layout.window.toNormH(400)}}>
        <Text style={styles.bottomSheetTitle}>Country code</Text>
        <Text style={styles.bottomSheetSubtitle}>Please select receiver's country code</Text>
        <div style={styles.horizontalLine}></div>
        <Input 
          placeholder={'Search'}
          value={searchInput}
          onChange={handleSearchChange}
        />

        <div style={styles.listContainer}>
          {optionsList?.map(country => <div className="d-flex flex-row py-1" style={styles.optionItem} onClick={() => {
            setCountryOptionsVisible(false);
            setSelectedCountry(country);
          }}>
            <img src={`https://flagcdn.com/48x36/${country?.code?.toLowerCase()}.png`} style={styles.countryFlag}></img>
            <Text className="m-0">{country?.name} ({country?.dial_code})</Text>
          </div>)}
        </div>
      </div>
    </BottomSheet>
    </>
  )
}
