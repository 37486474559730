import { ACTION_TYPES } from '../constants/actionTypes';

const initialState = {
  accessToken: '',
  refreshToken: '',
  isUserLoggedIn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AUTH.ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.data
      }
    case ACTION_TYPES.AUTH.REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.data
      }
    case ACTION_TYPES.AUTH.IS_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: action.data
      }
    default:
      return state;
  }
};

export default reducer;
