import React, { useEffect, useState } from 'react'
import Base from '../../components/Base'
import { loadInitialAuth } from '../../service/AuthService'
import { useNavigate } from 'react-router-dom'
import { APICallStatus } from '../../constants';
import Loader from '../../components/loader';
import { RotatingLines } from 'react-loader-spinner';
import { theme } from '../../constants/theme';
import { useSelector } from 'react-redux';
import { lastLoadUrlSelector } from '../../redux/selectors/app';

export default function LoadingAuth() {
  const navigate = useNavigate();
  const lastLoadUrl = useSelector(lastLoadUrlSelector);

  const onLoad = async () => {
    const response = await loadInitialAuth();
    if(response?.status === APICallStatus.SUCCESS) {
      // if(lastLoadUrl?.length > 1) {
      //   navigate(lastLoadUrl);
      // } else {
        navigate('/dashboard');
      // }
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{flex: 1, height: '100vh'}}>
      <RotatingLines
        strokeColor={theme.COLOR.ThemePrimary}
        strokeWidth="4"
        animationDuration="0.75"
        width="60"
        visible={true}
      />
    </div>
  )
}
