import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  bottomsheetContainer: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    // backgroundColor: '#00000020',
  },
  overlayView: {
    width: '400px',
    height: '600px',
    backgroundColor: '#00000040',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  sheetView: {
    width: layout?.isMobileDevice ? '100%':'400px',
    maxHeight: '80%',
    overflowY: 'scroll',
    backgroundColor: 'white',
    borderRadius: layout?.isMobileDevice ? '16px 16px 0 0':'30px',
    bottom: 0,
    padding: layout.window.toNormW(20),
  },
  mobileOverlay: {
    width: '100%',
    height: '100%',
    backgroundColor: '#00000040',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'flex-end',
  }
  // bottomSheet: {
  //   width: '100vw',
  //   position: 'absolute',
  //   bottom: 0,
  //   backgroundColor: theme.COLOR.Red,
  //   height: layout.window.toNormH(500),
  //   paddingTop: layout.window.toNormW(12),
  //   paddingLeft: layout.window.toNormW(22),
  //   paddingRight: layout.window.toNormW(22),
  //   borderRadius: '16px 16px 0 0',
  // }
}