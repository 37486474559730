import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  title: {
    fontSize: layout.window.toNormH(30),
    // lineHeight: layout.window.toNormH(42),
    fontFamily: theme.FONT.PrimaryBold,
    marginTop: layout.window.toNormH(22),
  },
  inputTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular,
    marginTop: layout.window.toNormH(22),
    marginBottom: layout.window.toNormH(12),
  },
  selectionBox: {
    minWidth: '50px',
    height: '50px',
    borderStyle: 'solid',
    borderWidth: layout.window.toNormW(1),
    borderColor: theme.COLOR.LightGrey,
    borderRadius: layout.window.toNormW(6),
    marginRight: layout.window.toNormW(10),
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: layout.window.toNormW(10),
    paddingRight: layout.window.toNormW(10),
  },
  inputContainer: {
    flex: 1
  },
  countryFlag: {
    width: layout.window.toNormW(25),
    height: layout.window.toNormH(20),
    marginRight: layout.window.toNormW(12)
  },
  bottomSheetTitle: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold
  },
  bottomSheetSubtitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular
  },
  horizontalLine: {
    width: '100%',
    height: layout.window.toNormH(2),
    backgroundColor: theme.COLOR.LightGrey,
    marginTop: layout.window.toNormH(12),
    marginBottom: layout.window.toNormH(20),
  },
  listContainer: {
    width: '100%',
    maxHeight: layout.window.toNormH(320),
    marginTop: layout.window.toNormH(22),
    overflow: 'scroll',
  },
  optionItem: {
    marginBottom: layout.window.toNormH(12),
    width: '100%',
  },
}