import React from 'react'
import { styles } from './styles'
import Input from '../Input'
import layout from '../../constants/layout';

export default function BottomSheet({
  visible,
  onClose,
  children
}) {
  if(!visible) {
    return null;
  } else if (layout.isMobileDevice) {
    return (
      <div style={styles.mobileOverlay}>
        <div style={styles.sheetView}>
          {children}
        </div>
      </div>
    )
  } else {
    return (
      <div className='d-flex justify-content-center' style={styles.bottomsheetContainer}>
        <div style={styles.overlayView}>
          <div style={styles.sheetView}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
