export const Config = {
  apis: {
    baseUrl: `https://prod-lb-n-1003.monyyapp.com/api`,
    sendPushNotification: `/v1/firebase-notification/sendNotificationsDashboard`,
    auth: {
      login: `/v1/auth/login`,
      verifyOtp: `/v1/auth/authenticate`,
      fetchUserProfile: `/v1/auth/getUserInfo`,
      submitUserProfile: `/v1/onboarding/web/submitPersonalDetails`,
    },
    bbps: {
      fetchRechargePlans: `/v1/bbps/web/getOperatorAndCircleInfoAndMobilePlan`,
      initiateRecharge: `/v1/wallet/topup`,
      rechargePolling: `/v1/wallet/topupPolling`,
      bbpsPolling: `/v1/bbps/web/bbpsPrepaidRechargeStatus`,
      fetchElectricityBoards: '/v1/bbps/web/getAllElectricityBoards',
      fetchElectricityBillDetails: '/v1/bbps/web/fetchElectricityBillDetails',
    }
  }
}