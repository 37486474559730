import React from 'react'
import layout from '../../constants/layout'
import { theme } from '../../constants/theme';
import { RotatingLines } from 'react-loader-spinner';
import Text from '../../components/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/loader';
import CustomButton from '../../components/CustomButton';
import { BbpsPollingStatus, PollingStatus } from '../../constants';
import { useNavigate } from 'react-router-dom';

const styles = {
  statusWebContainer: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    // backgroundColor: '#00000020',
  },
  overlayView: {
    width: '400px',
    height: '600px',
    backgroundColor: theme.COLOR.WhitePrimary,
    borderRadius: '30px',
    display: 'flex',
  },
  statusContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: theme.COLOR.WhitePrimary,
    padding: layout.window.toNormW(22),
  },
  loaderView: {
    flex: 1,
    paddingTop: layout.window.toNormH(22),
  },
  statusView: {
    flex: 1,
    marginTop: layout.window.toNormH(102),
    paddingLeft: layout.window.toNormW(22),
    paddingRight: layout.window.toNormW(22),
  },
  loaderTitle: {
    fontSize: layout.window.toNormH(24),
    fontFamily: theme.FONT.PrimarySemiBold,
    marginTop: layout.window.toNormH(100),
    marginBottom: layout.window.toNormH(16),
  },
  loaderSubTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey2,
    textAlign: 'center'
  },
  statusTitle: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.PrimaryBlack,
    textAlign: 'center',
    marginTop: layout.window.toNormH(22),
  },
  paymentAmount: {
    fontSize: layout.window.toNormH(24),
    fontFamily: theme.FONT.PrimarySemiBold,
    color: theme.COLOR.PrimaryBlack,
    textAlign: 'center',
    // marginTop: layout.window.toNormH(10),
  },
  rechargeStatusContainer: {
    backgroundColor: `${theme.COLOR.YellowOrange}20`,
    padding: layout.window.toNormW(5),
    marginTop: layout.window.toNormH(20),
    borderRadius: layout.window.toNormW(5),
  },
  rechargeStatusText: {
    fontSize: layout.window.toNormH(12),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.YellowOrangeDark,
  },
  rechargeStatusSuccessContainer: {
    backgroundColor: `${theme.COLOR.SuccessGreen}20`,
    padding: layout.window.toNormW(5),
    marginTop: layout.window.toNormH(20),
    borderRadius: layout.window.toNormW(5),
  },
  rechargeStatusSuccessText: {
    fontSize: layout.window.toNormH(12),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.SuccessGreen,
  },
  rechargeStatusFailedContainer: {
    backgroundColor: `${theme.COLOR.SuccessGreen}20`,
    padding: layout.window.toNormW(5),
    marginTop: layout.window.toNormH(20),
    borderRadius: layout.window.toNormW(5),
  },
  rechargeStatusFailedText: {
    fontSize: layout.window.toNormH(12),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.SuccessGreen,
  },
  cta: {
    // position:'absolute',
    marginTop: layout.window.toNormH(220),
  },
  ctaMobile: {
    position:'absolute',
    bottom: layout.window.toNormH(22),
    marginTop: layout.window.toNormH(220),
    width: '85%'
  }
}

export default function StatusPage({
  isLoading,
  paymentStatus,
  rechargeStatus,
  rechargeAmount,
}) {
  const navigate = useNavigate();

  const getPaymentStatusBasedData = () => {
    switch (paymentStatus) {
      case PollingStatus.SUCCESS:
        return {
          icon: <FontAwesomeIcon icon={faCheckCircle} color={theme.COLOR.SuccessGreen} fontSize={layout.window.toNormH(60)}/>,
          title: 'Payment Successful',
        };
      case PollingStatus.FAILURE:
        return {
          icon: <FontAwesomeIcon icon={faTimesCircle} color={theme.COLOR.Red} fontSize={layout.window.toNormH(60)}/>,
          title: 'Payment Failed',
        };
      case PollingStatus.PROCESSING:
      default:
        return {
          icon: <FontAwesomeIcon icon={faExclamationCircle} color={theme.COLOR.YellowOrange} fontSize={layout.window.toNormH(60)}/>,
          title: 'Payment Processing',
        };
    }
  }

  const getRechargeStatusBasedData = () => {
    switch (rechargeStatus) {
      case BbpsPollingStatus.SUCCESS:
        return {
          statusText: 'Successful',
          statusContainerStyle: styles.rechargeStatusSuccessContainer,
          statusTextStyle: styles.rechargeStatusSuccessText,
        };
      case BbpsPollingStatus.FAILURE:
        return {
          statusText: 'Failed',
          statusContainerStyle: styles.rechargeStatusFailedContainer,
          statusTextStyle: styles.rechargeStatusFailedText,
        };
      case BbpsPollingStatus.PROCESSING:
      default:
        return {
          statusText: 'Confirmation Pending',
          statusContainerStyle: styles.rechargeStatusContainer,
          statusTextStyle: styles.rechargeStatusText,
        };
    }
  }

  if (layout.isMobileDevice) {
    return (
      <div className='d-flex flex-column' style={styles.statusContainer}>
        {isLoading ? <div className='d-flex flex-column justify-content-center align-items-center' style={styles.loaderView}>
          <RotatingLines
            strokeColor={theme.COLOR.ThemePrimary}
            strokeWidth="4"
            animationDuration="0.75"
            width="60"
            visible={true}
          />
          <Text style={styles.loaderTitle}>Payment processing...</Text>
          <Text style={styles.loaderSubTitle}>This might take upto few minutes.</Text>
          <Text style={styles.loaderSubTitle}>Please do not refresh or go back.</Text>
        </div> : <div className='d-flex flex-column align-items-center' style={styles.statusView}>
            {getPaymentStatusBasedData().icon}
            <Text style={styles.statusTitle}>{getPaymentStatusBasedData().title}</Text>
            <Text style={styles.paymentAmount}>₹ {rechargeAmount}</Text>

            {paymentStatus === PollingStatus.SUCCESS ? <div className='d-flex flex-row align-items-center justify-content-center'>
              <div style={getRechargeStatusBasedData().statusContainerStyle}>
                <Text style={getRechargeStatusBasedData().statusTextStyle}>Recharge status: {getRechargeStatusBasedData().statusText}</Text>
              </div>
              {rechargeStatus === BbpsPollingStatus.PROCESSING ? <div className='mx-2 mt-3' style={{alignItems: 'center'}}>
                <RotatingLines
                  strokeColor="black"
                  strokeWidth="4"
                  animationDuration="0.75"
                  width="15"
                  visible={true}
                />
              </div> : null}
            </div> : null}

            {(paymentStatus === PollingStatus.SUCCESS && rechargeStatus !== BbpsPollingStatus.PROCESSING) || (paymentStatus !== PollingStatus.SUCCESS) ?
            <CustomButton title={'Done'} style={styles.ctaMobile} onClick={() => navigate('/dashboard')}/> : null}
          </div>}
      </div>
    );
  } else {
    return (
      <div className='d-flex justify-content-center' style={styles.statusWebContainer}>
        <div className='d-flex flex-column' style={styles.overlayView}>
          {isLoading ? <div className='d-flex flex-column justify-content-center align-items-center' style={styles.loaderView}>
            <RotatingLines
              strokeColor={theme.COLOR.ThemePrimary}
              strokeWidth="4"
              animationDuration="0.75"
              width="60"
              visible={true}
            />
            <Text style={styles.loaderTitle}>Payment processing...</Text>
            <Text style={styles.loaderSubTitle}>This might take upto few minutes.</Text>
            <Text style={styles.loaderSubTitle}>Please do not refresh or go back.</Text>
          </div> : <div className='d-flex flex-column align-items-center' style={styles.statusView}>
            {getPaymentStatusBasedData().icon}
            <Text style={styles.statusTitle}>{getPaymentStatusBasedData().title}</Text>
            <Text style={styles.paymentAmount}>₹ {rechargeAmount}</Text>

            {paymentStatus === PollingStatus.SUCCESS ? <div className='d-flex flex-row align-items-center justify-content-center'>
              <div style={getRechargeStatusBasedData().statusContainerStyle}>
                <Text style={getRechargeStatusBasedData().statusTextStyle}>Recharge status: {getRechargeStatusBasedData().statusText}</Text>
              </div>
              {rechargeStatus === BbpsPollingStatus.PROCESSING ? <div className='mx-2 mt-3' style={{alignItems: 'center'}}>
                <RotatingLines
                  strokeColor="black"
                  strokeWidth="4"
                  animationDuration="0.75"
                  width="15"
                  visible={true}
                />
              </div> : null}
            </div> : null}

            {(paymentStatus === PollingStatus.SUCCESS && rechargeStatus !== BbpsPollingStatus.PROCESSING) || (paymentStatus !== PollingStatus.SUCCESS) ?
            <CustomButton title={'Done'} style={styles.cta} onClick={() => navigate('/dashboard')}/> : null}
          </div>}
        </div>
      </div>
    );
  }
}
