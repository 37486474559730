import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  title: {
    fontSize: layout.window.toNormH(30),
    // lineHeight: layout.window.toNormH(42),
    fontFamily: theme.FONT.PrimaryBold,
    marginTop: layout.window.toNormH(22),
  },
  inputTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular,
    marginTop: layout.window.toNormH(22),
    marginBottom: layout.window.toNormH(12),
  },
  inputContainer: {
    flex: 1
  },
}