import React from 'react'
import { theme } from '../../constants/theme'
import layout from '../../constants/layout'
import { Button } from 'react-bootstrap'
import Text from '../Text'
import Loader from '../loader'
const styles = {
  button: {
    width: "100%",
    backgroundColor: theme.COLOR.ThemePrimary,
    paddingTop: layout.window.toNormH(12),
    paddingBottom: layout.window.toNormH(12),
  },
  disabledButton: {
    width: "100%",
    backgroundColor: theme.COLOR.ThemePrimaryDisabled,
    paddingTop: layout.window.toNormH(12),
    paddingBottom: layout.window.toNormH(12),
  }
}
export default function CustomButton({
  title,
  onClick,
  className,
  style = {},
  isLoading=false,
  isDisabled=false,
}) {
  return (
    <Button
      className={className ? className : ''}
      style={isLoading || isDisabled ? {...styles.disabledButton, ...style} : {...styles.button, ...style}}
      onClick={onClick}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? <Loader/> : <Text>{title}</Text>}
    </Button>
  )
}
