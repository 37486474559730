import React from 'react'
import { styles } from './styles'
import { Button } from 'react-bootstrap'
import Text from '../Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import layout from '../../constants/layout'
import Loader from '../loader'
import { ToastContainer } from 'react-toastify'

export default function Base({
  headerAttributes,
  footerAttributes,
  children
}) {
  return (
    <div className='d-flex flex-column' style={{flex: 1}}>
      {headerAttributes ? <div className='d-flex flex-row align-items-center' style={styles.headerContainer}>
        {headerAttributes?.onBack ? <div className='d-flex align-items-center' style={styles.backIconContainer} onClick={headerAttributes?.onBack}>
          <FontAwesomeIcon icon={faArrowLeft} style={styles.backIcon}/>
        </div> : null}
        <Text style={styles.headerTitle}>{headerAttributes?.title}</Text>
      </div> : null}
      <div className='d-flex flex-column' style={!headerAttributes ? {...styles.container, paddingTop: layout.window.toNormH(22),} : styles.container}>
        <ToastContainer 
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {children}
      </div>
      {footerAttributes ? <div style={styles.footerContainer}>
        {footerAttributes?.primaryAttributes ? <Button
          // className="py-2"
          style={footerAttributes?.primaryAttributes?.isLoading || footerAttributes?.primaryAttributes?.isDisabled ? styles.disabledButton : styles.button}
          onClick={footerAttributes?.primaryAttributes?.onClick}
          disabled={footerAttributes?.primaryAttributes?.isLoading || footerAttributes?.primaryAttributes?.isDisabled}
        >
          {footerAttributes?.primaryAttributes?.isLoading ? <Loader/> : <Text>{footerAttributes?.primaryAttributes?.title}</Text>}
        </Button> : null}
      </div> : null}
    </div>
  )
}
