import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  dropdownContainer: {
    // width: '100%',
    height: '50px',
    // backgroundColor: 'red',
    borderStyle: 'solid',
    borderWidth: layout.window.toNormW(1),
    borderColor: theme.COLOR.LightGrey,
    borderRadius: layout.window.toNormW(6),
  },
  inputLabel: {
    fontSize: layout.window.toNormH(16),
    // color: theme.COLOR.Grey2,
    marginBottom: layout.window.toNormH(10),
  },
  dropdownText: {
    fontSize: layout.window.toNormH(16),
    color: theme.COLOR.PrimaryBlack,
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: layout.window.toNormW(310)
  },
  bottomSheetTitle: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimarySemiBold
  },
  bottomSheetSubtitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular
  },
  horizontalLine: {
    width: '100%',
    height: layout.window.toNormH(2),
    backgroundColor: theme.COLOR.LightGrey,
    marginTop: layout.window.toNormH(12),
    marginBottom: layout.window.toNormH(20),
  },
  listContainer: {
    width: '100%',
    maxHeight: layout.window.toNormH(250),
    flex: 1,
    marginTop: layout.window.toNormH(22),
    overflow: 'scroll',
  },
}