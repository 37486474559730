import { ACTION_TYPES } from "../constants/actionTypes";

const setAccessToken = (data) => ({
  type: ACTION_TYPES.AUTH.ACCESS_TOKEN,
  data
});

const setRefreshToken = (data) => ({
  type: ACTION_TYPES.AUTH.REFRESH_TOKEN,
  data
});

const setIsUserLoggedIn = (data) => ({
  type: ACTION_TYPES.AUTH.IS_USER_LOGGED_IN,
  data
});

export {
  setAccessToken,
  setRefreshToken,
  setIsUserLoggedIn,
};