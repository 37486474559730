export const ACTION_TYPES = {
  APP: {
    USER_PROFILE: 'USER_PROFILE',
    LAST_LOAD_URL: 'LAST_LOAD_URL',
  },
  AUTH: {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    IS_USER_LOGGED_IN: 'IS_USER_LOGGED_IN',
  },
  SCREEN_PROPS: {
    SET_SCREEN_PROPS: 'SET_SCREEN_PROPS',
  },
};
