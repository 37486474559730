import React from 'react'
import layout from '../../constants/layout'

export default function AppWrapper({ children }) {
  if(layout.isMobileDevice) {
    return (
      children
    )
  } else {
    return (
      <div className='web-container d-flex justify-content-center align-items-center'>
        <div className='mobile-view d-flex'>
          {children}
          {/* <div className='loader-view'></div> */}
        </div>
      </div>
    )
  }
}
