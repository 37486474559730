import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  headerName: {
    fontSize: layout.window.toNormH(20),
    fontFamily: theme.FONT.PrimaryRegular,
  },
  horizontalLine: {
    width: '100%',
    height: layout.window.toNormH(2),
    backgroundColor: theme.COLOR.LightGrey,
    marginTop: layout.window.toNormH(12),
    marginBottom: layout.window.toNormH(20),
  },
  divisionContainer: {
    // width: '300px'
  },
  divisionTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
  },
  divisionSubTitle: {
    fontSize: layout.window.toNormH(12),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey2,
    marginTop: layout.window.toNormH(4),
  },
  bannerContainer: {
    position: 'relative',
    alignSelf: 'center',
    backgroundColor: 'red',
    width: '90%',
    maxWidth: '90%',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap'
  },
  banner: {
    width: layout.window.toNormW(200),
    height: layout.window.toNormW(100),
    backgroundColor: theme.COLOR.Grey1,
    borderRadius: layout.window.toNormW(10),
    marginRight: layout.window.toNormW(10),
  },
  iconContainer: {
    width: layout.window.toNormW(60),
    height: layout.window.toNormW(60),
    borderRadius: layout.window.toNormW(50),
    backgroundColor: theme.COLOR.Grey4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  optionTitle: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey2,
    marginTop: layout.window.toNormH(8),
    textAlign: 'center'
  },
  optionTitleDisabled: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey3,
    marginTop: layout.window.toNormH(8),
    textAlign: 'center'
  },
}