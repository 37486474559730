import { ACTION_TYPES } from '../constants/actionTypes';

const setUserProfile = (data) => ({
  type: ACTION_TYPES.APP.USER_PROFILE,
  data
});
const setLastLoadUrl = (data) => ({
  type: ACTION_TYPES.APP.LAST_LOAD_URL,
  data
});

export {
  setUserProfile,
  setLastLoadUrl,
};
