import React from 'react'
import { theme } from '../../constants/theme'

export default function Text({
  className,
  style,
  children,
}) {
  return (
    <p className={`${className}`} style={{ marginBottom: 0, fontFamily: theme.FONT.PrimaryRegular, ...style}}>{children}</p>
  )
}
