import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";


export default function Loader() {

  return (
    <RotatingLines
      strokeColor="black"
      strokeWidth="6"
      animationDuration="0.75"
      width="20"
      visible={true}
    />
  );
}
