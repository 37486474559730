import { createSelector } from "reselect";
import store from "../store";

export const screenPropsSliceSelector = (state) => state.screenProps || {};
export const propsSelector = createSelector(screenPropsSliceSelector, screenPropsState => screenPropsState || {});

export const screenPropsSelector = (screenName) => {
  const state = store.getState();
  if(state?.screenProps && state?.screenProps[screenName]){
    return state?.screenProps[screenName];
  }

  return {
    screenName,
  }
}