import React, { useState } from 'react'
import Base from '../../components/Base'
import { useNavigate } from 'react-router-dom'
import { styles } from './styles';
import Text from '../../components/Text';
import Input from '../../components/Input';
import CustomButton from '../../components/CustomButton';
import { fetchRechargePlans, initiateRecharge } from '../../service/BbpsService';
import { APICallStatus, KeyboardTypes } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../constants/theme';
import Navigation from '../../navigation';
import { RotatingLines } from 'react-loader-spinner';
import { showErrorToast } from '../../utilities/toast';

export default function MobileRecharge() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [rechargePlanData, setRechargePlanData] = useState();
  const [isLoadingPlanData, setIsLoadingPlanData] = useState(false);
  const [selectedPlanTypeData, setSelectedPlanTypeData] = useState();
  const [isLoadingPaymentIntent, setIsLoadingPaymentIntent] = useState(null);

  const onClickSearchPlans = async () => {
    setIsLoadingPlanData(true);
    const response = await fetchRechargePlans({
      number: phoneNumber,
    });
    setIsLoadingPlanData(false);
    if(response?.status === APICallStatus.SUCCESS) {
      setRechargePlanData(response?.data);
      setSelectedPlanTypeData(response?.data?.planDetails[0])
    } else {
      showErrorToast(response?.message);
    }
  }

  const onPlanClick = async (plan) => {
    setIsLoadingPaymentIntent(plan?.price);
    const response = await initiateRecharge({
      amount: plan?.price,
      referenceId: rechargePlanData?.referenceId,
    });
    setIsLoadingPaymentIntent(null);
    if(response?.status === APICallStatus.SUCCESS) {
      Navigation.showCheckout({
        paymentIntentClientSecret: response?.paymentIntentClientSecret,
        paymentIntentId: response?.paymentIntentId,
        referenceId: rechargePlanData?.referenceId,
        amount: plan?.price,
      }, () => navigate('/checkout'));
    } else {
      showErrorToast(response?.message);
    }
  }
  
  return (
    <Base
      headerAttributes={{
        title: 'Mobile Recharge',
        onBack: () => navigate(-1),
      }}
    >
      <Text style={styles.inputTitle}>Enter mobile number for recharge</Text>
      <Input
        placeholder={'XXXXXXXXXX'}
        type={KeyboardTypes.TELEPHONE}
        inputContainerStyles={{
        }}
        value={phoneNumber}
        onChange={(value) => {
          setPhoneNumber(value);
          setRechargePlanData(null);
        }}
      />
      <CustomButton title={'Search plans'} style={styles.searchButton} isLoading={isLoadingPlanData} isDisabled={!(phoneNumber?.length===10)} onClick={onClickSearchPlans}/>
      
      {rechargePlanData ? <>
        <div className='d-flex flex-row justify-content-between' style={styles.operatorDetailsDiv}>
          <div className='d-flex flex-column align-items-start'>
            <Text style={styles.operatorDetailsTitle}>Operator</Text>
            <Text style={styles.operatorDetailsText}>{rechargePlanData?.operatorName}</Text>
          </div>
          <div className='d-flex flex-column align-items-end'>
            <Text style={styles.operatorDetailsTitle}>Circle</Text>
            <Text style={styles.operatorDetailsText}>{rechargePlanData?.circleName}</Text>
          </div>
        </div>
        <div style={styles.horizontalLine}></div>
        <Text style={styles.choosePlanTitle}>Choose Plan</Text>
        <div className='d-flex flex-row mt-3 hideScrollbar' style={styles.planTypesContainer}>
          {rechargePlanData?.planDetails?.map(planTypeData => <div className='p-2 d-flex flex-row flex-nowrap' style={planTypeData?.planType === selectedPlanTypeData?.planType ? styles.activePlanTypeItem : styles.planTypeItem} onClick={() => setSelectedPlanTypeData(planTypeData)}>
            <Text style={planTypeData?.planType === selectedPlanTypeData?.planType ? styles.activePlanTypeTitle : styles.planTypeTitle}>{planTypeData?.planType}</Text>
          </div>)}
        </div>
        <div className='d-flex flex-column' style={styles.plansScrollContainer}>
          {selectedPlanTypeData?.availablePlans?.map(planData => <div className='my-2' style={styles.plansContainer} onClick={() => onPlanClick(planData)}>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Text style={styles.planValidity}>Validity: {planData?.validity}</Text>
              <div className='d-flex flex-row align-items-center'>
                <Text style={styles.planAmount}>₹ {planData?.price}</Text>
                {isLoadingPaymentIntent === planData?.price ? <RotatingLines
                  strokeColor="black"
                  strokeWidth="4"
                  animationDuration="0.75"
                  width="15"
                  visible={true}
                /> : <FontAwesomeIcon icon={faChevronCircleRight} color={theme.COLOR.ThemePrimary}/>}
              </div>
            </div>
            <div style={styles.horizontalLinePlan}></div>
            <Text style={styles.planDescription}>Talktime: ₹ {planData?.talkTime} | Validity: {planData?.validity} | {planData?.packageDescription}</Text>
          </div>)}
        </div>
      </> : null}
    </Base>
  )
}
