import React, { useState } from 'react'
import Base from '../../components/Base'
import { styles } from './styles'
import Text from '../../components/Text'
import Input from '../../components/Input'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { propsSelector } from '../../redux/selectors/screenProps'
import { SCREENS } from '../../constants/screens'
import { verifyOtp } from '../../service/AuthService'
import Navigation from '../../navigation'
import { APICallStatus, KeyboardTypes } from '../../constants'
import { showErrorToast } from '../../utilities/toast'

export default function OtpVerification() {
  const navigate = useNavigate();
  const { phoneNumber } = useSelector(propsSelector)[SCREENS.OtpVerification];
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if(!(phoneNumber?.length > 0)) {
    navigate('/');
  }

  const onContinue = async () => {
    setIsLoading(true);
    const response = await verifyOtp({
      phoneNumber: phoneNumber.split('+')[1],
      otp: otp
    });
    setIsLoading(false);
    if(response.status === APICallStatus.SUCCESS) {
      if(response?.isUserDataAvailable) {
        Navigation.showPersonalDetailsScreen({}, () => navigate('/dashboard'));
      } else {
        Navigation.showPersonalDetailsScreen({}, () => navigate('/personalDetails'));
      }
    } else {
      showErrorToast(response?.message);
    }
  }

  return (
    <Base
      footerAttributes={{
        primaryAttributes: {
          title: 'Verify',
          onClick: onContinue,
          isLoading: isLoading,
          isDisabled: !(otp?.length === 6)
        }
      }}
    >
      <Text style={styles.title}>OTP Verification</Text>
      <Text style={styles.inputTitle}>Enter the code we sent to {phoneNumber}</Text>
      <Input
        placeholder={'OTP'}
        type={KeyboardTypes.NUMBER}
        inputContainerStyles={{
        }}
        value={otp}
        onChange={setOtp}
      />
    </Base>
  )
}
