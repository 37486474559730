import React, { useState } from 'react'
import Base from '../../components/Base'
import { styles } from './styles'
import Text from '../../components/Text'
import Input from '../../components/Input'
import { useNavigate } from 'react-router-dom'
import { submitUserProfile } from '../../service/AuthService'
import { APICallStatus } from '../../constants'
import Navigation from '../../navigation'
import { showErrorToast } from '../../utilities/toast'

export default function PersonalDetails() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onClickSubmit = async () => {
    setIsLoading(true);
    const response = await submitUserProfile({
      name,
      email,
    });
    setIsLoading(false);
    if(response?.status === APICallStatus.SUCCESS) {
      Navigation.showDashboard({}, () => navigate('/dashboard'));
    } else {
      showErrorToast(response?.message);
    }
    // () => navigate('/dashboard')
  }
  return (
    <Base
      footerAttributes={{
        primaryAttributes: {
          title: 'Submit',
          onClick: onClickSubmit,
          isLoading: isLoading,
          isDisabled: !(name?.length > 0 && email?.length > 3)
        }
      }}
    >
      <Text style={styles.title}>Personal Details</Text>
      <Text style={styles.inputTitle}>Enter your name as per Govt. issued ID</Text>
      <Input
        placeholder={'Name'}
        inputContainerStyles={{
        }}
        value={name}
        onChange={setName}
      />
      <Text style={styles.inputTitle}>Enter your email address</Text>
      <Input
        placeholder={'Email ID'}
        inputContainerStyles={{
        }}
        value={email}
        onChange={setEmail}
      />
    </Base>
  )
}
