import { APICallStatus } from "../../constants";
import { Config } from "../../constants/config";
import { setUserProfile } from "../../redux/actions/app";
import { setAccessToken, setIsUserLoggedIn, setRefreshToken } from "../../redux/actions/auth";
import store from "../../redux/store";
import { ApiService } from "../ApiService";
import { StorageService } from "../StorageService";

export const phoneSignin = async ({phoneNumber, countryCode, dialCode}) => {
  try {
    const response = await ApiService.postApi(Config.apis.auth.login, {
      phoneNumber: phoneNumber,
      countryCode,
      dialCode,
    });
    console.log(response,'xyzxyz login response');
    return {
      status: APICallStatus.SUCCESS,
    };
  } catch (error) {
    console.log(error, error?.response?.data?.error?.shortMessage, 'xyzxyz login error');
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong.',
    };
  }
}

export const verifyOtp = async ({phoneNumber, otp}) => {
  try {
    const dispatch = store.dispatch;
    const response = await ApiService.postApi(Config.apis.auth.verifyOtp, {
      phoneNumber: phoneNumber,
      otp
    });
    const accessToken = response?.data?.data?.accessToken;
    const refreshToken = response?.data?.data?.refreshToken;
    saveTokens(accessToken, refreshToken);
    const responseUserProfile = await fetchUserProfile();
    let isUserDataAvailable = false;
    if(responseUserProfile?.status === APICallStatus.SUCCESS && responseUserProfile?.data?.fullName?.length > 0 && responseUserProfile?.data?.email?.length > 0) {
      isUserDataAvailable = true;
    }
    return {
      status: APICallStatus.SUCCESS,
      isUserDataAvailable,
    };
  } catch (error) {
    console.log(error);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong.',
    };
  }
}

export const saveTokens = (accessToken, refreshToken) => {
  try {
    const dispatch = store.dispatch;
    if(accessToken?.length>5 && refreshToken?.length>5) {
      dispatch(setIsUserLoggedIn(true));
    } else {
      dispatch(setIsUserLoggedIn(false));
    }
    dispatch(setAccessToken(accessToken));
    dispatch(setRefreshToken(refreshToken));
    StorageService.persistAccessToken(accessToken);
    StorageService.persistRefreshToken(refreshToken);
  } catch (error) {
    console.log(error);
  }
}

export const fetchUserProfile = async () => {
  try {
    const dispatch = store.dispatch;
    const response = await ApiService.postApi(Config.apis.auth.fetchUserProfile, {
    });
    dispatch(setUserProfile(response?.data?.data));
    return {
      status: APICallStatus.SUCCESS,
      data: response?.data?.data,
    }
  } catch (error) {
    console.log(error);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong.',
    }
  }
}

export const submitUserProfile = async ({name, email}) => {
  try {
    const response = await ApiService.postApi(Config.apis.auth.submitUserProfile, {
      fullName: name,
      email: email,
    });

    return {
      status: APICallStatus.SUCCESS,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong.',
    };
  }
}

export const loadInitialAuth = async () => {
  try {
    const accessToken = await StorageService.getAccessToken();
    const refreshToken = await StorageService.getRefreshToken();
    saveTokens(accessToken, refreshToken);
    const userProfile = await fetchUserProfile();
    return {
      status: userProfile?.status,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
    };
  }
}