import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isUserLoggedInSelector } from "../../redux/selectors/auth";
import { setLastLoadUrl } from "../../redux/actions/app";

const ProtectedRoute = ({ children }) => {
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const dispatch = useDispatch();
  if (!isUserLoggedIn) {
    try {
      dispatch(setLastLoadUrl(`/${window.location.href?.split('/')[window.location.href?.split('/').length-1]}`)); 
    } catch (error) {
      
    }
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
