// import { Dimensions, Platform } from "react-native";
const base_width = 400;
const base_height = 600;

const screen_width = window.innerWidth
const screen_height = window.innerHeight

const isWeb = screen_width > 400 && screen_height > 600;

const deviceWidth = screen_width > base_width ? base_width : screen_width;
const deviceHeight =  screen_height > base_height ? base_height : screen_height;
//Platform.OS == 'web' && screen_height > base_height ? base_height : screen_height;
// the zeplin screens are provided for a screen resolution of 375 x 812 px
// to make the screen layout equivalent on all screen sizes, we use the original pixel size provided in zeplin
// the sizes are scaled according to the device specific width x height using a normalization factor
// example: {height: 30 * window.normH, width: 100 * window.normW}

export default {
  window: {
    deviceWidth,
    deviceHeight,
    normW: Math.fround(deviceWidth / base_width),
    normH: Math.fround(deviceHeight / base_height),
    toNormW: (width, dW = deviceWidth) => {
      return (width * dW) / base_width;
    },
    toNormH: (height, dH = deviceHeight) => {
      return (height * dH) / base_height;
    },
  },
  isMobileDevice: deviceWidth < base_width,
  aspect_16To9: Math.fround(16 / 9),
  deviceHeightForWeb : () => {
    const deviceHeight = screen_height;
    if (deviceHeight < 800 && deviceHeight > 400) {
      return deviceHeight * 0.9;
    } else if (deviceHeight > 800) {
      return 700;
    } else if (deviceHeight < 400) {
      return deviceHeight;
    }
    return deviceHeight
  }
};
