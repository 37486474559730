import React, { useEffect, useState } from 'react'
import Base from '../../components/Base'
import { useNavigate } from 'react-router-dom'
import { fetchElectricityBillDetails, fetchElectricityBoards } from '../../service/BbpsService';
import { APICallStatus, KeyboardTypes } from '../../constants';
import { showErrorToast } from '../../utilities/toast';
import Text from '../../components/Text';
import { styles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Input from '../../components/Input';
import BottomSheet from '../../components/BottomSheet';
import layout from '../../constants/layout';
import Navigation from '../../navigation';

export default function Electricity() {
  const navigate = useNavigate('');
  const [electricityBoards, setElectricityBoards] = useState([]);
  const [selectedElectricityBoard, setSelectedElectricityBoard] = useState();
  const [boardOptionsVisible, setBoardOptionsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [optionsList, setOptionsList] = useState([]);
  const [mobileNumber, setMobileNumber] = useState('');
  const [inputFields, setInputFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onLoad = async () => {
    try {
      const response = await fetchElectricityBoards();
      if(response?.status === APICallStatus.SUCCESS) {
        setElectricityBoards(response?.electricityBoards);
        setOptionsList(response?.electricityBoards);
        setSelectedElectricityBoard(response?.electricityBoards[0]);
        onClickOption(response?.electricityBoards[0]);
      } else {
        showErrorToast(response?.message)
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  const handleSearchChange = (value) => {
    setSearchInput(value);
    if(!value || value?.length === 0) {
      setOptionsList(electricityBoards);
    }
    let filteredList = [];
    electricityBoards.forEach(board => {
      if(board?.billerName?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1) {
        filteredList.push(board);
      }
    })
    setOptionsList(filteredList);
  }

  const onClickOption = (board) => {
    setBoardOptionsVisible(false);
    setSelectedElectricityBoard(board);
    let inputFields = [];
    board?.customerParams?.forEach(param => {
      if(param?.paramName?.toLowerCase() !== 'mobile number') {
        inputFields?.push({
          ...param,
          value: '',
        });
      }
    });
    setInputFields(inputFields);
  }

  const handleInputChange = (inputField, value) => {
    let updatedInputFields = [];
    inputFields.forEach(inputParam => {
      if(inputParam?.paramName === inputField?.paramName) {
        updatedInputFields.push({
          ...inputField,
          value: value,
        });
      } else {
        updatedInputFields.push({
          ...inputParam,
        });
      }
    });
    setInputFields(updatedInputFields);
  }

  const onClickFetchBill = async () => {
    try {
      setIsLoading(true);
      let customerParams = {};
      inputFields.forEach(field => {
        customerParams[field?.paramName] = field?.value
      });
      selectedElectricityBoard?.customerParams?.forEach(param => {
        if(param?.paramName?.toLowerCase() === 'mobile number') {
          customerParams[param?.paramName] = mobileNumber
        }
      });
      const response = await fetchElectricityBillDetails({
        billerId: selectedElectricityBoard?.billerId,
        customerParams: customerParams,
        mobileNo: mobileNumber,
      });
      setIsLoading(false);
      if(response?.status === APICallStatus.SUCCESS) {
        if(response?.billStatus === "FAILURE") {
          showErrorToast('Bill not found.');
        } else {
          Navigation.showElectricityBillDetails({
            amount: response?.amount,
            accountHolderName: response?.accountHolderName,
            billDate: response?.billDate,
            dueDate: response?.dueDate,
            referenceId: response?.referenceId,
            billerId: response?.billerId,
          }, ()=>navigate('/electricityBillDetails'))
        }
      } else {
        showErrorToast(response?.message);
      }
    } catch (error) {
      
    }
  }

  const isFormVaild = () => {
    let isValid = true;
    if(mobileNumber?.length !== 10) {
      isValid = false;
      return isValid;
    }
    inputFields.forEach(field => {
      if(!(field?.value?.length >= parseInt(field?.minLength) && field?.value?.length <= parseInt(field?.maxLength))) {
        isValid = false;
      }
    })
    return isValid
  }

  return (
    <>
      <Base 
        headerAttributes={{
          title: 'Electricity',
          onBack: () => navigate(-1),
        }}
        footerAttributes={{
          primaryAttributes: {
            title: 'Fetch bill',
            onClick: onClickFetchBill,
            isLoading: isLoading,
            isDisabled: !isFormVaild(),
          },
        }}
      >
        <div className='d-flex flex-column'>
          <Text style={styles.inputLabel}>Choose electricity board</Text>
          <div className='d-flex flex-row align-items-center px-2' style={styles.dropdownContainer} onClick={() => {
            setSearchInput('');
            setOptionsList(electricityBoards);
            setBoardOptionsVisible(true)
          }}>
            <Text style={styles.dropdownText}>{selectedElectricityBoard?.billerName}</Text>
            <FontAwesomeIcon className='mx-2' icon={faAngleDown}/>
          </div>
        </div>
        <div className='d-flex flex-column mt-3'>
          <Input
            label={'Account holder number (+91)'}
            type={KeyboardTypes.TELEPHONE}
            value={mobileNumber}
            onChange={setMobileNumber}
          />
        </div>
        {inputFields?.map(inputField => <div className='d-flex flex-column mt-3'>
          <Input
            label={inputField?.paramName}
            type={inputField?.dataType === 'NUMERIC' ? KeyboardTypes.NUMBER : KeyboardTypes.TEXT}
            value={inputField?.value}
            onChange={(value) => handleInputChange(inputField, value)}
          />
        </div>)}
      </Base>
      <BottomSheet visible={boardOptionsVisible} onClose={() => setBoardOptionsVisible(false)}>
        <div style={{height: layout.window.toNormH(400)}}>
          <Text style={styles.bottomSheetTitle}>Electricity boards</Text>
          <Text style={styles.bottomSheetSubtitle}>Please select your electricity board</Text>
          <div style={styles.horizontalLine}></div>
          <Input 
            placeholder={'Search'}
            value={searchInput}
            onChange={handleSearchChange}
          />
          <div style={styles.listContainer}>
            {optionsList?.map(board => <div className="d-flex flex-row py-1" style={styles.optionItem} onClick={() => {
              onClickOption(board);
            }}>
              <Text>{board?.billerName}</Text>
            </div>)}
          </div>
        </div>
      </BottomSheet>
    </>
  )
}
