import layout from "../../constants/layout";
import { theme } from "../../constants/theme";

export const styles = {
  title: {
    fontSize: layout.window.toNormH(30),
    // lineHeight: layout.window.toNormH(42),
    fontFamily: theme.FONT.PrimaryBold,
    marginTop: layout.window.toNormH(22),
  },
  inputTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimaryRegular,
    // marginTop: layout.window.toNormH(22),
    marginBottom: layout.window.toNormH(12),
  },
  inputContainer: {
    flex: 1
  },
  searchButton: {
    marginTop: layout.window.toNormH(22),
  },
  operatorDetailsDiv: {
    marginTop: layout.window.toNormH(22),
  },
  operatorDetailsTitle: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey2
  },
  operatorDetailsText: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    // color: theme.COLOR.Grey2
  },
  horizontalLine: {
    width: '100%',
    height: layout.window.toNormH(2),
    backgroundColor: theme.COLOR.LightGrey,
    marginTop: layout.window.toNormH(10),
    marginBottom: layout.window.toNormH(10),
  },
  horizontalLinePlan: {
    width: '100%',
    height: layout.window.toNormH(2),
    backgroundColor: theme.COLOR.LightGrey,
    marginTop: layout.window.toNormH(6),
    marginBottom: layout.window.toNormH(6),
  },
  choosePlanTitle: {
    fontSize: layout.window.toNormH(16),
    fontFamily: theme.FONT.PrimarySemiBold,
    textAlign: 'center',
    color: theme.COLOR.Grey2,
  },
  planTypesContainer: {
    overflowX: 'scroll',
    // maxWidth: layout.window.toNormW(100),
    width: layout.window.toNormW(355),
    paddingBottom: layout.window.toNormH(8),
  },
  planTypeItem: {
    borderWidth: layout.window.toNormW(1),
    borderColor: theme.COLOR.Grey3,
    borderStyle: 'solid',
    borderRadius: layout.window.toNormW(10),
    whiteSpace: 'nowrap',
    marginRight: layout.window.toNormW(5),
  },
  planTypeTitle: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimaryRegular,
    textAlign: 'center',
    color: theme.COLOR.Grey2,
  },
  activePlanTypeItem: {
    borderWidth: layout.window.toNormW(1),
    borderColor: theme.COLOR.ThemePrimary,
    backgroundColor: theme.COLOR.ThemePrimary,
    borderStyle: 'solid',
    borderRadius: layout.window.toNormW(10),
    whiteSpace: 'nowrap',
    marginRight: layout.window.toNormW(5),
  },
  activePlanTypeTitle: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimaryRegular,
    textAlign: 'center',
    color: theme.COLOR.WhitePrimary,
  },
  plansScrollContainer: {
    flex: 1,
    overflowY: 'scroll',
    // height: 100
  },
  plansContainer: {
    paddingTop: layout.window.toNormH(10),
    paddingBottom: layout.window.toNormH(10),
    paddingRight: layout.window.toNormW(8),
    paddingLeft: layout.window.toNormW(8),
    borderWidth: layout.window.toNormW(1),
    borderRadius: layout.window.toNormW(6),
    borderColor: theme.COLOR.Grey3,
    borderStyle: 'solid',
  },
  planValidity: {
    fontSize: layout.window.toNormH(12),
    fontFamily: theme.FONT.PrimarySemiBold,
  },
  planAmount: {
    fontSize: layout.window.toNormH(14),
    fontFamily: theme.FONT.PrimarySemiBold,
    marginRight: layout.window.toNormW(8),
  },
  planDescription: {
    fontSize: layout.window.toNormH(12),
    fontFamily: theme.FONT.PrimaryRegular,
    color: theme.COLOR.Grey2,
  }
}