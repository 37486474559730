import React, { useState } from 'react'
import Base from '../../components/Base'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { propsSelector } from '../../redux/selectors/screenProps';
import { SCREENS } from '../../constants/screens';
import Text from '../../components/Text';
import { styles } from './styles';
import { initiateRecharge } from '../../service/BbpsService';
import Navigation from '../../navigation';
import { APICallStatus, BbpsOption } from '../../constants';
import { showErrorToast } from '../../utilities/toast';

export default function ElectricityBillDetails() {
  const navigate = useNavigate('');
  const props = useSelector(propsSelector)[SCREENS.ElectricityBillDetails];
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentClick = async () => {
    try {
      setIsLoading(true);
      console.log(props)
      const response = await initiateRecharge({
        amount: props?.amount,
        referenceId: props?.referenceId,
      });
      setIsLoading(false);
      if(response?.status === APICallStatus.SUCCESS) {
        Navigation.showCheckout({
          paymentIntentClientSecret: response?.paymentIntentClientSecret,
          paymentIntentId: response?.paymentIntentId,
          referenceId: props?.referenceId,
          amount: props?.amount,
          accountHolderName: props?.accountHolderName,
          billDate: props?.billDate,
          dueDate: props?.dueDate,
          referenceId: props?.referenceId,
          billerId: props?.billerId,
          bbpsOption: BbpsOption.ELECTRICITY,
        }, () => navigate('/checkout'));
      } else {
        showErrorToast(response?.message);
      }
    } catch (error) {
      
    }
  }
  return (
    <Base
      headerAttributes={{
        title: 'Bill details',
        onBack: () => navigate(-1),
      }}
      footerAttributes={{
        primaryAttributes: {
          title: 'Pay',
          // isDisabled: true
          isLoading: isLoading,
          onClick: handlePaymentClick
        }
      }}
    >
      <div className='d-flex flex-row justify-content-between align-items-center mb-3' style={styles.detailRow}>
        <div style={styles.textLeft}>
          <Text style={styles.detailTitle}>Account holder</Text>
        </div>
        <div style={styles.textRight}>
          <Text style={styles.detailText}>{props.accountHolderName}</Text>
        </div>
      </div>

      <div className='d-flex flex-row justify-content-between align-items-center mb-3' style={styles.detailRow}>
        <div style={styles.textLeft}>
          <Text style={styles.detailTitle}>Bill date</Text>
        </div>
        <div style={styles.textRight}>
          <Text style={styles.detailText}>{props.billDate}</Text>
        </div>
      </div>

      <div className='d-flex flex-row justify-content-between align-items-center mb-3' style={styles.detailRow}>
        <div style={styles.textLeft}>
          <Text style={styles.detailTitle}>Due date</Text>
        </div>
        <div style={styles.textRight}>
          <Text style={styles.detailText}>{props.dueDate}</Text>
        </div>
      </div>

      <div className='d-flex flex-row justify-content-between align-items-center mb-3' style={styles.detailRow}>
        <div style={styles.textLeft}>
          <Text style={styles.detailTitle}>Billed amount</Text>
        </div>
        <div style={styles.textRight}>
          <Text style={styles.detailText}>₹ {props.amount}</Text>
        </div>
      </div>
    </Base>
  )
}
