import axios from "axios";
import { Config } from "../constants/config";
// import { useUserAuth } from "../context/UserAuthContext";
// import { userProfileSelector } from "../redux/selectors/app";
// import { accessTokenSelector } from "../redux/selectors/auth";
import store from "../redux/store";
import { accessTokenSelector } from "../redux/selectors/auth";

// const getHeaders = () => {
//   const { logOut, user } = useUserAuth();

//   return {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${user.accessToken}`,
//     },
//   };
// };


const postApi = (url, params) => {
  const accessToken = accessTokenSelector(store.getState());
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "source": "web",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const body = {
    metaData: {
    },
    data: {
      ...params
    },
  };
  console.log(body, 'api request body')
  return axios.post(
    `${Config.apis.baseUrl}${url}`,
    body,
    headers,
  );
}

export const ApiService = {
  postApi,
}