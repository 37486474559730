const APICallStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

const PollingStatus = {
  PENDING: 'SETTLEMENT_PENDING',
  INITIATED: 'SETTLEMENT_INITIATED',
  FAILURE: 'SETTLEMENT_FAILURE',
  PROCESSING: 'SETTLEMENT_PROCESSING',
  SUCCESS: 'SETTLEMENT_SUCCESSFUL',
}

const BbpsPollingStatus = {
  FAILURE: 'FAILED',
  PROCESSING: 'PENDING',
  SUCCESS: 'SUCCESS',
}

const KeyboardTypes = {
  EMAIL: 'email',
  NUMBER: 'number',
  TEXT: 'text',
  TELEPHONE: 'tel',
}

const BbpsOption = {
  MOBILE_RECHARGE: 'MOBILE_RECHARGE',
  ELECTRICITY: 'ELECTRICITY',
}

export {
  APICallStatus,
  PollingStatus,
  BbpsPollingStatus,
  KeyboardTypes,
  BbpsOption,
}