export const theme = {
  COLOR: {
    ThemePrimary: '#613AE7',
    PrimaryBlack: '#0F0E17',
    PrimaryBlackDisabled: 'rgba(15, 14, 23, 0.6)',
    Grey1: '#242A38',
    Grey2: '#4E596F',
    Grey2Disabled: '#959BA9',
    Grey3: '#A7A9BE',
    Grey4: '#E8E8EB',
    PrimaryGreen: '#14CA89',
    PrimaryRed: '#FC264C',
    PrimaryWhite: '#FFFFFF',
    ThemePrimaryDisabled: '#A089F1',
    PrimaryYellowOrange: '#FFBB02',
    Transparent: 'transparent',
    Grey: '#898989',
    Grey100: '#767676',
    Grey200: '#EEEEEE',
    WhitePrimary: '#ffffff',
    LightGrey: '#CAD1DA',
    LightGrey100: '#F5F8F9',
    SuccessGreen: '#16BF69',
    Red: '#FF656F',
    YellowOrange: '#ffb200',
    YellowOrangeDark: '#FF9300',
  },
  FONT: {
    PrimaryRegular: 'PrimaryRegular',
    PrimarySemiBold: 'PrimarySemiBold',
    PrimaryBold: 'PrimaryBold',
    PrimaryLight: 'PrimaryLight',
    PrimaryRegularItalic: 'PrimaryRegularItalic',
    PrimarySemiBoldItalic: 'PrimarySemiBoldItalic',
    PrimaryLight: 'PrimaryLight',
    PrimaryBoldItalic: 'PrimaryBoldItalic',
  }
}