import { APICallStatus, BbpsPollingStatus, PollingStatus } from "../../constants";
import { Config } from "../../constants/config"
import { ApiService } from "../ApiService"

export const fetchRechargePlans = async ({number}) => {
  try {
    const response = await ApiService.postApi(Config.apis.bbps.fetchRechargePlans, {
      mobileNo: number,
    });
    console.log(response, 'xyzxyz recharge response');
    return {
      status: APICallStatus.SUCCESS,
      data: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    };
  }
}

export const initiateRecharge = async ({amount, referenceId}) => {
  try {
    const response = await ApiService.postApi(Config.apis.bbps.initiateRecharge, {
      amount: amount,
      promoCodeId: null,
      paymentGateway:"STRIPE",
      method:"CARD",
      openPgDetails: null,
      purpose: "BBPS",
      referenceId: referenceId
    });
    console.log(response, 'xyzxyz initiate payment response');
    return {
      status: APICallStatus.SUCCESS,
      paymentIntentClientSecret: response?.data?.data?.paymentIntentClientSecret,
      paymentIntentId: response?.data?.data?.paymentIntentId,
    }
  } catch (error) {
    console.log(error, 'xyzxyz initiate payment error');
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    }
  }
}

export const handlePaymentPollingLogic = async (paymentIntentId, callback) => {
  try {
    const timeout = 90000;
    let currentTime = 0;
    let responseSuccess = false;
    let lastApiResult = null;
    let currentTimerValue = 2000;
    const timer = setInterval(async () => {
      // currentTimerValue = 2000;
      try {
        let result = await ApiService.postApi(Config.apis.bbps.rechargePolling, {
          paymentIntentId: paymentIntentId
        });
        console.log(result,'api response')
        lastApiResult = result;
        currentTime += currentTimerValue;
        if (result?.data?.data?.status === PollingStatus.SUCCESS || result?.data?.data?.status === PollingStatus.FAILURE || currentTime >= timeout) {
          responseSuccess = true;
          callback(result?.data?.data);
          clearInterval(timer);
        }
      } catch (error) {
        responseSuccess = true;
        callback({
          status: PollingStatus.PROCESSING
        });
        clearInterval(timer);
      }
    }, currentTimerValue);

    setTimeout(() => {
      if (!responseSuccess) {
        clearInterval(timer);
        callback(lastApiResult?.data?.data);
      }
    }, timeout + 2000);
  } catch (error) {
    console.log(error);
  }
}

export const handleBbpsPollingLogic = async (referenceId, callback) => {
  try {
    const timeout = 90000;
    let currentTime = 0;
    let responseSuccess = false;
    let lastApiResult = null;
    let currentTimerValue = 2000;
    const timer = setInterval(async () => {
      // currentTimerValue = 2000;
      try {
        let result = await ApiService.postApi(Config.apis.bbps.bbpsPolling, {
          referenceId: referenceId,
        });
        console.log(result,'api response')
        lastApiResult = result;
        currentTime += currentTimerValue;
        if (result?.data?.data?.status === BbpsPollingStatus.SUCCESS || result?.data?.data?.status === BbpsPollingStatus.FAILURE || currentTime >= timeout) {
          responseSuccess = true;
          callback(result?.data?.data);
          clearInterval(timer);
        }
      } catch (error) {
        responseSuccess = true;
        callback({
          status: BbpsPollingStatus.PROCESSING
        });
        clearInterval(timer);
      }
    }, currentTimerValue);

    setTimeout(() => {
      if (!responseSuccess) {
        clearInterval(timer);
        callback(lastApiResult?.data?.data);
      }
    }, timeout + 2000);
  } catch (error) {
    console.log(error);
  }
}

export const fetchElectricityBoards = async () => {
  try {
    const response = await ApiService.postApi(Config.apis.bbps.fetchElectricityBoards, {});
    console.log(response);
    return {
      status: APICallStatus.SUCCESS,
      electricityBoards: response?.data?.data,
    }
  } catch (error) {
    console.log(error)
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    }
  }
}

export const fetchElectricityBillDetails = async ({billerId, customerParams, mobileNo}) => {
  try {
    const response = await ApiService.postApi(Config.apis.bbps.fetchElectricityBillDetails, {
      billerId,
      category: "ELECTRICITY",
      customerParams,
      mobileNo,
    });
    console.log(response, 'xyzxyz response');
    return {
      ...response?.data?.data,
      billStatus: response?.data?.data?.status,
      status: APICallStatus.SUCCESS,
    };
  } catch (error) {
    console.log(error, 'xyzxyz error');
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    };
  }
}