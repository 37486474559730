import { ACTION_TYPES } from '../constants/actionTypes';

const initialState = {
  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SCREEN_PROPS.SET_SCREEN_PROPS:
      return {
        ...state,
        [action.data.screenName]: action.data,
      }
    default:
      return state;
  }
};

export default reducer;
